import { n as normalizeComponent } from "./vueComponentNormalizer.js";
var render$1 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("simple-form", { scopedSlots: _vm._u([{ key: "default", fn: function(ref) {
    var handleSubmit = ref.handleSubmit;
    return [_c("a-modal", { attrs: { "ok-text": "Save", "title": "Edit Subclass Translation", "after-close": _vm.afterModalClose, "width": 520 }, on: { "ok": _vm.submit }, scopedSlots: _vm._u([{ key: "footer", fn: function() {
      return [_c("a-button", { on: { "click": _vm.onCancel } }, [_vm._v("Cancel")]), _c("a-button", { attrs: { "loading": _vm.isLoading, "type": "primary" }, on: { "click": function($event) {
        return handleSubmit(_vm.submit);
      } } }, [_vm._v("Save")])];
    }, proxy: true }], null, true), model: { value: _vm.visible, callback: function($$v) {
      _vm.visible = $$v;
    }, expression: "visible" } }, [_c("a-row", { attrs: { "gutter": 24 } }, [_c("a-col", { attrs: { "span": 4 } }, [_c("select-input", { key: "country", attrs: { "label": "Country", "reference": "web-analytics.common.ppv-countries", "placeholder": "Select Country", "source": "countryId", "source-label": "countryCode", "disabled": true, "value": _vm.entity.countryId, "form-item": "" } })], 1), _c("a-col", { attrs: { "span": 10 } }, [_c("select-input", { key: "subclass", attrs: { "placeholder": "Select Subclass", "data-source": _vm.listSubclass, "label": "Subclass", "source": "subClass", "source-label": "subClassDesc", "value": _vm.entity.subclass.substring(0, _vm.entity.subclass.indexOf(" ")), "disabled": true, "form-item": "" } })], 1), _c("a-col", { attrs: { "span": 10 } }, [_c("select-input", { key: "projectTypeId", attrs: { "placeholder": "Select Project Type", "data-source": _vm.listProjectType, "label": "Project Type", "source": "projectTypeId", "source-label": "projectType", "rules": "required", "value": _vm.entity.projectTypeId, "form-item": "" }, on: { "change": _vm.onProjectTypeChange } })], 1)], 1)], 1)];
  } }]) });
};
var staticRenderFns$1 = [];
const apiUrl$1 = "#{VUE_APP_API_URL}#";
const __vue2_script$1 = {
  name: "EditProjectSubclassTranslations",
  inject: ["crud"],
  data() {
    return {
      apiUrl: apiUrl$1,
      visible: false,
      isLoading: false,
      projectTypeId: "",
      listProjectType: [],
      listSubclass: []
    };
  },
  computed: {
    entity() {
      return this.crud.getEntity();
    }
  },
  mounted() {
    this.visible = true;
    this.getListProjectType();
    this.getListSubclass();
  },
  methods: {
    async submit() {
      this.isLoading = true;
      await this.axios.put(`${this.apiUrl}/web-analytics/project-subclass-translations`, {
        countryId: this.entity.countryId,
        subclass: this.entity.subclass.substring(0, this.entity.subclass.indexOf(` `)),
        projectTypeId: this.projectTypeId ? this.projectTypeId : this.entity.projectTypeId
      }).then(() => {
        this.isLoading = false;
        this.visible = false;
        this.crud.fetchList();
        this.$notification["success"]({
          message: "Item Updated!"
        });
      }).catch((err) => {
        this.isLoading = false;
        this.$notification["error"]({
          message: err.response.data.message || "Something went wrong, please try again!"
        });
      });
    },
    getListProjectType() {
      this.axios.get(`${this.apiUrl}/web-analytics/common/ppv-project-type`).then((res) => {
        this.listProjectType = [...res.data];
      });
    },
    getListSubclass() {
      this.axios.get(`${this.apiUrl}/web-analytics/common/ppv-sub-class?CountryID=${this.entity.countryId}`).then((res) => {
        this.listSubclass = [...res.data];
      });
    },
    afterModalClose() {
      this.$router.push("/web-analytics/project-subclass-translations");
    },
    onCancel() {
      this.visible = false;
    },
    onProjectTypeChange(value) {
      this.projectTypeId = value;
    }
  }
};
const __cssModules$1 = {};
var __component__$1 = /* @__PURE__ */ normalizeComponent(__vue2_script$1, render$1, staticRenderFns$1, false, __vue2_injectStyles$1, null, null, null);
function __vue2_injectStyles$1(context) {
  for (let o in __cssModules$1) {
    this[o] = __cssModules$1[o];
  }
}
var EditProjectSubclassTranslations = /* @__PURE__ */ function() {
  return __component__$1.exports;
}();
var render = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", [_c("resource", { attrs: { "api-url": _vm.apiUrl, "name": "web-analytics.common.ppv-countries" } }), _c("resource", { attrs: { "api-url": _vm.apiUrl, "name": "web-analytics.project-subclass-translations" } }, [_c("edit-project-subclass-translations")], 1)], 1);
};
var staticRenderFns = [];
const apiUrl = "#{VUE_APP_API_URL}#";
const __vue2_script = {
  components: {
    EditProjectSubclassTranslations
  },
  data() {
    return {
      apiUrl
    };
  }
};
const __cssModules = {};
var __component__ = /* @__PURE__ */ normalizeComponent(__vue2_script, render, staticRenderFns, false, __vue2_injectStyles, null, null, null);
function __vue2_injectStyles(context) {
  for (let o in __cssModules) {
    this[o] = __cssModules[o];
  }
}
var index = /* @__PURE__ */ function() {
  return __component__.exports;
}();
export { index as default };
